@import "~react-image-gallery/styles/scss/image-gallery.scss";

@font-face {
  font-family: "Poppins";
  src: url("../assets//font/Poppins/Poppins-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
label,
span,
li,
button {
  font-family: "Poppins" !important;
}

body {
  font-family: "Poppins" !important;
}

.h-700px {
  height: 700px;
  @media (max-width: 640px) {
    height: 450px;
  }
}

.bg-image {
  background-image: url(../assets/image/Banner.png);
}

.bg-pink {
  background-color: pink;
}

.comingsoon {
  font-size: 84px;
  font-weight: 700;
  color: white;
  letter-spacing: 3px;
  @media (max-width: 640px) {
    font-size: 48px;
  }
}

.image {
  width: 300px;
  height: 100px;
  margin: auto;

  @media (max-width: 640px) {
    width: 200px;
  }
}

.txt-white {
  color: white;
}

.text-121127 {
  color: #121127;
  opacity: 56%;
}

.contact {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 24px;
  }
}

.m-0px {
  margin: 0px !important;
}

.button {
  background-color: #150491 !important;
  border-radius: 5px !important;
  padding: 14px 32px 14px 32px;
}

@media (max-width: 640px) {
  .sm-fsize14 {
    font-size: 14px;
  }
}

.c-textareatype {
  width: 100%;
  border-style: solid;
  border: 1px solid var(--qtheme-color-darkL1);
  border-radius: 2px;
  padding: 1rem;
  font-weight: 300;
}

.overflow-y-auto {
  overflow-y: auto;
}

.progresss {
  border-color: "--color-primary";
  border-width: "0 0 5px 0";
  height: "1px";
  min-width: "90%";
}

// Swiper

.swiper-wrapper {
  display: flex !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 32px !important;
}
.swiper {
  height: auto;
}
@media screen and (min-width: 576px) {
  .swiper-container {
    width: 576px;
  }
}
@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}
@media (min-width: 992px) {
  .swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.swiper {
  width: 100% !important;
}

// .active {
//   color: #121127 !important;
// }
.clr-blue {
  color: grey;
}
@media (max-width: 640px) {
  .newrswipe .swiper-backface-hidden .swiper-slide {
    margin-right: 70px !important;
  }

  .image-gallery-swipe, .image-gallery-slides {
    height: 225px !important;
    
  }
  
  img.image-gallery-image {
    height: 225px !important;
  }
  
  .image-gallery-slide .image-gallery-image {
    object-fit: cover !important;
  }
}

.inputs {
  padding: 16px 16px 16px 16px;
  width: 100%;
  border: 2px solid #b4b4b4;
  @media (max-width: 640px) {
    padding: 8px 16px 8px 16px;
  }
}

.imgGallery {
  display: block;
  width: 100%;
  object-fit: cover;
  
  height: 100%;

 
  margin: 0px 0px 20px 0px;
  border-radius: 14px;
}

.textarea {
  width: 100%;
  height: 90px !important;
}

.image-gallery-play-button {
  display: none;
}






