.hoverText1 {
    position: relative;
  }
  
.hoverText1:before {
    content: "+";
    /* visibility: hidden;
    opacity: 0; */
    width: 34px;
    height: 34px;
    background: #2b3737;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 22px;
    border-radius: 999px;
    cursor: pointer;
    transition: opacity 1s ease-in-out;
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0px;
  }
  .sidebarfixcls {
    position: fixed !important;
  }
  .sidebarclass {
    width: 100% !important;
    z-index: 99 !important;
    background: #0000006e !important;
  }
  .sideheader {
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
  .flexsetjustify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px !important;
    background: #222 !important;
    color: #fff !important;
  }
  .px-20px {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pt-10px {
    padding-top: 10px;
  }
  .tooltip-w {
    width: auto !important;
  }
  
  .submitbutton {
    width: 50%;
    background: var(--qtheme-color-primary) !important;
    border: none;
    border-radius: 30px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    margin: auto;
    height: 45px;
  }
  .inputcss {
    width: 100%;
    height: 38px;
    padding-left: 17px;
    padding-left: 17px;
  
    border: 1px solid #80808069;
    border-radius: 4px;
  }
  .mt-30px {
    margin-top: 30px;
  }
  .imageCss {
    width: 100%;
    height: 300px;
    margin-top: 10px;
    border: 2px dashed #80808069;
    border-radius: 4px;
  }
  .menucss-al {
    margin-right: 30px;
  }
  .mb-5px {
    margin-bottom: 5px;
  }
  .my-0px {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  /*  */
  .stop-abcall {
    position: inherit !important;
  }
  .stop-abchild {
    position: inherit !important;
  }
  .qkl-header {
    position: relative;
    z-index: 99;
  }
  .w-full {
    width: 100% !important;
  }

  .sidebar-overlay {
    width: 30%;
    margin-left: auto;
    background: #fff;
    height: 100%;

    @media (max-width: 505px) {      
        width: 75% !important;      
    }
  }