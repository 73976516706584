@font-face {
  font-family: "Poppins";
  src: url("../assets//font/Poppins/Poppins-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
label,
span,
li,
button {
    font-family: "Poppins" !important;
}

body {
    font-family: "Poppins" !important;
}

.h-700px {
    height: 700px;
  }
  
  .bg-red {
    background-color: red;
    background-image: url(../assets/image/Banner.png);
  }
  
  .bg-pink {
    background-color: pink;
  }
  
  .comingsoon {
    font-size: 84px;
      font-weight: 700;
      color: white;
      letter-spacing: 3px;
  }
  
  .image {
    width: 300px;
    height: 100px;
  
    margin: auto;
  }
  
  .txt-white {
    color: white;
  }
  
  .text-121127 {
    color: #121127;
    opacity: 56%;
  }
  
  
  
  .contact {
    font-size: 40px;
      font-weight: 600;
      text-align: center;
  }
  
  .m-0px {
    margin: 0px !important;
  }
  
  .button {
    background-color: #150491 !important;   
    border-radius: 5px !important;
    padding: 14px 32px 14px 32px;
    
  }